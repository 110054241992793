module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-104426888-2","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"googleTagManager":{"trackingId":"","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-104426888-2"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
